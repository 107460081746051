<template>
  <b-card v-if="engineer">
    <b-card-title style="font-size: xx-large; text-align: center;">
      Engineer Information
    </b-card-title>
    <b-card-text
      v-if="engineer.name"
    ><strong>Name: </strong>{{ engineer.name }}</b-card-text>
    <b-card-text
      v-if="engineer.email"
    ><strong>Email: </strong>{{ engineer.email }}</b-card-text>
    <b-card-text
      v-if="engineer.phone"
    ><strong>Phone: </strong>{{ engineer.phone }}</b-card-text>
    <b-card-text
      v-if="engineer.department_name"
    ><strong>Department Name: </strong>{{ engineer.department_name }}</b-card-text>

    <b-card-text
      v-if="engineer.job_description"
    ><strong>Job Description: </strong>{{ engineer.job_description }}</b-card-text>

    <b-card-text
      v-if="engineer.joined_at"
    ><strong>Joined At: </strong>{{ engineer.joined_at }}</b-card-text>
    <b-card-text
      v-if="engineer.load"
    ><strong>Load: </strong>{{ engineer.load }}</b-card-text>
    <div style="display: flex; flex-wrap: wrap; gap: 10px;">

      <!-- Status -->
      <div
        v-if="engineer.status.length > 0"
        style="border: 2px solid black; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Status
        </b-card-title>
        <b-card-text
          v-for="(state , index) in engineer.status"
          :key="index"
        >
          {{ index + 1 }} - {{ state.status }}
        </b-card-text>
      </div>

      <!-- office -->

      <div
        v-if="engineer.office"
        style="border: 2px solid black; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Office
        </b-card-title>
        <b-card-text
          v-if="engineer.office.name"
        ><strong>Office Name: </strong>{{ engineer.office.name }}</b-card-text>
        <b-card-text
          v-if="engineer.office.country"
        ><strong>Office Country: </strong>{{ engineer.office.country }}</b-card-text>
      </div>
      <!-- Schedule -->

      <div
        v-if="engineer.schedule.length > 0"
        style="border: 2px solid black; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Schedule
        </b-card-title>
        <div
          v-for="(schedule , index) in engineer.schedule"
          :key="index"
          style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
        >
          <b-card-text>
            <b-card-text
              v-if="schedule.from"
            ><strong>From </strong>{{ schedule.from }}</b-card-text>
            <b-card-text
              v-if="schedule.to"
            ><strong>To: </strong>{{ schedule.to }}</b-card-text>
            <b-card-text
              v-if="schedule.status"
            ><strong>status: </strong>{{ schedule.status }}</b-card-text>
            <b-card-text
              v-if="schedule.description"
            ><strong>Description: </strong>{{ schedule.description }}</b-card-text>
          </b-card-text>
        </div>
      </div>
      <!-- Courses -->

      <div
        v-if="engineer.courses.length > 0"
        style="border: 2px solid black; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Courses
        </b-card-title>
        <div
          v-for="(courses , index) in engineer.courses"
          :key="index"
          style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
        >
          <b-card-text>
            <b-card-text
              v-if="courses.name_ar || courses.name_en"
            ><strong>Name: </strong>{{ courses.name_ar }} - {{ courses.name_en }}</b-card-text>
            <b-card-text
              v-if="courses.description_en || courses.description_ar"
            ><strong>Description: </strong>{{ courses.description_ar }} - {{ courses.description_en }}</b-card-text>
            <b-card-text
              v-if="courses.issuer"
            ><strong>Issuer: </strong>{{ courses.issuer }}</b-card-text>
            <b-card-text
              v-if="courses.expired_at"
            ><strong>Expired At: </strong>{{ courses.expired_at }}</b-card-text>
            <b-card-text
              v-if="courses.doc.url"
            ><a
              :href="courses.doc.url"
              target="_blank"
            > <feather-icon icon="DownloadCloudIcon" /></a></b-card-text>
          </b-card-text>
        </div>
      </div>

      <!-- Certificates Groups -->

      <div
        v-if="engineer.certificates_groups.length > 0"
        style="border: 2px solid black; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Certificates Groups
        </b-card-title>
        <div
          v-for="(group, index) in engineer.certificates_groups"
          :key="index"
          style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
        >
          <b-card-title>
            {{ group.name.en }} ({{ group.name.ar }})
          </b-card-title>
          <div
            v-for="(certificate, certIndex) in group.certificates"
            :key="certIndex"
            style="border: 1px solid #d8d6de; width: fit-content; padding: 10px; margin-top: 5px;"
          >
            <b-card-text>
              <strong>Certificate: </strong>{{ certificate.name.en }} ({{ certificate.name.ar }})
            </b-card-text>
          </div>
        </div>
      </div>

    </div></b-card>
</template>

<script>
import axios from 'axios'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { FeatherIcon },
  data() {
    return {
      engineer: {},
    }
  },
  mounted() {
    this.getEngineerInfo()
  },
  methods: {
    getEngineerInfo() {
      axios.get(`/engineer/${this.$route.params.id}`).then(res => {
        this.engineer = decryptData(res.data.payload).data.engineer
      })
    },
  },
}
</script>

  <style scoped>
  .engineer-details {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  </style>
